<template>
    <div>
      <TopPage title="Logs" :options="topOptions" complement="" />

      <div class="flex mb-3">
        <div class="mr-3">
          <label for="data" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data</label>
          <input type="date" v-model="params.date" id="data" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
        </div>
        <div class="mr-3">
          <label for="tipo" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tipo</label>
          <select id="tipo" v-model="params.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value="1" selected>Plano</option>
            <option value="2">Pagamento</option>
            <option value="3">Usuário</option>
          </select>
        </div>
        <div class="mr-3">
          <label for="before" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Antes</label>
          <input type="text" v-model="params.before" id="data" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
        </div>
        <div class="mr-3">
          <label for="after" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Depois</label>
          <input type="text" v-model="params.after" id="data" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
        </div>
        <div>
          <label for="tipo" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">&nbsp;</label>
          <button type="button" @click="getData" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Buscar</button>
        </div>
      </div>
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th  scope="col" class="px-6 py-3">ID</th>
            <th  scope="col" class="px-6 py-3">Tipo</th>
            <th  scope="col" class="px-6 py-3">Usuário</th>
            <th  scope="col" class="px-6 py-3">Antes</th>
            <th  scope="col" class="px-6 py-3">Depois</th>
            <th  scope="col" class="px-6 py-3">Data</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="item in data" :key="item.id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td class="px-6 py-4">{{item.auditable_id}}</td>
              <td class="px-6 py-4">{{item.auditable_type}}</td>
              <td class="px-6 py-4">{{item.user ? item.user.name : ''}}</td>
              <td class="px-6 py-4">
                <vue-json-pretty :data="item.old_values" />
              </td>
              <td class="px-6 py-4">
                <vue-json-pretty :data="item.new_values" />
              </td>
              <td>{{helpers.formatDate(item.created_at)}}</td>
            </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import TopPage from '@/components/TopPage.vue';
import { helpers } from '@/utils/helpers';
import { ApiService } from '@/services/api.service';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { initFlowbite } from 'flowbite'

const BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
});
export default {
    name: 'Operational',
    components: {
      VueJsonPretty,
      TopPage
    },
    mounted () {
      initFlowbite()
    },
    data() {
        return {
            helpers: helpers,
            data: [],
            params: {
              type: 1,
              date: null,
              before: null,
              after: null
            },
            topOptions: {
                datefilter: false,
                actions: true
            },
            columns: [
                { label: 'ID', name: 'id', orderable: true },
                { label: 'Tipo', name: 'auditable_type', orderable: false },
                { label: 'Usuário', name: 'user.name', orderable: false },
                { 
                  label: 'Antes',
                  type: 'json',
                  name: 'old_values',
                  orderable: false,
                  transform: row => {
                      return helpers.formatDate(row.data.created_at);
                  }
                },
                { 
                  label: 'Depois',
                  type: 'json',
                  name: 'new_values',
                  orderable: false,
                  transform: row => {
                      return helpers.formatDate(row.data.created_at);
                  }
                },
                {
                    label: 'Data',
                    name: 'created_at',
                    orderable: true,
                    transform: row => {
                        return helpers.formatDate(row.data.created_at);
                    }
                }
            ]
        };
    },
    methods: {
      async getData() {
          this.loading = true;
          console.log(this.params);

          clearTimeout(this.timerDebounce)
          this.timerDebounce = setTimeout(async () => {
            await ApiService.customRequest({ url: 'audits', params: this.params})
                  .then(response => {
                      this.data = response.data.data;
                  })
                  .catch(error => {
                      console.log(error);
                  })
                  .finally(() => {
                      this.loading = false;
                  });
          }, 500);
      },
    }
};
</script>
